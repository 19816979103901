import { useState } from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { StatusIndicator } from 'packages/eid-ui'
import { Link, useHistory } from 'react-router-dom'
import {
    OverviewSectionSkeleton,
    Person,
    ProgressIndicator,
    TextFormatter,
} from 'components'
import moment from 'moment'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
    usePerson,
    useRequestItemAccessRecipient,
    useRequestItemEditFulfillmentDate,
} from 'hooks'
import {
    AttributeContainer,
    AttributeLabelLarge,
    AttributeValue,
    Container,
    styles,
    useStyles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import { getFullImageUrl, isDateInPast, roundOffNumber } from 'utils'
import { useRegistry } from 'core/useRegistry'
import useSubcomponents from 'useSubcomponents'
import { FulfillmentScheduleDateControl } from 'components/FulfillmentScheduleDateControl'
import { Button } from 'components'
import { Icon } from 'packages/eid-icons'
import { SubPathLevel } from 'appConfigurations'
import appConfig from 'config'

const NS = 'public'
const statusStyles = {
    color: '#9b9b9b',
    maxWidth: '270px',
}
const showMoreButtonStyles = {
    position: 'absolute',
    bottom: '0px',
    right: '5px',
    minWidth: '120px',
    minHeight: '32px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    color: '#307fc1',
    textTransform: 'uppercase !important',
    borderRadius: '16px',
    fontSize: '11px',
    lineHeight: '1.45',
    '&:hover': {
        backgroundColor: '#ffffff',
        color: '#307fc1',
        '& span': {
            color: '#307fc1',
        },
    },
}

const timeSectionStyles = {
    display: 'flex',

    '& svg': {
        marginLeft: '4px !important',
    },
}

const Overview = ({ data, refetch }) => {
    const registry = useRegistry()
    const { t } = useTranslation()
    const theme = useTheme()

    const [showMore, setShowMore] = useState(false)

    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]

    const classes = useStyles()

    const {
        hasAccessToFulfillmentScheduleDateControl,
        hasAccessToEditFulfillmentScheduleDateControl,
    } = useSubcomponents()

    const { data: accessRecipient } = useRequestItemAccessRecipient(
        data?.id,
        data?.businessRequestId,
    )

    const { data: initiatorPerson } = usePerson(data?.initiatorPersonId)

    const itemStatus = data?.preApproved ? 'PreApproved' : data?.status

    const [editFulfillmentDate] = useRequestItemEditFulfillmentDate(data.id)

    if (!data || !accessRecipient) {
        return (
            <Container>
                <OverviewSectionSkeleton />
            </Container>
        )
    }

    const CustomAttribute = ({ label, value, ...rest }) => {
        return (
            <Box {...rest}>
                <Box
                    color="#8b909a"
                    fontSize="12px"
                    style={{ textTransform: 'uppercase' }}
                >
                    {label}
                </Box>
                <Box fontSize="14px">{value}</Box>
            </Box>
        )
    }
    const AdditionalField = ({ label, value, ...rest }) => {
        return (
            <Box {...rest}>
                <Box fontWeight="bold">{label}</Box>
                <Box color="#5d6870" fontSize="12px">
                    {value}
                </Box>
            </Box>
        )
    }

    const subjectTypeInstance = registry.getSubjectType(
        accessRecipient.subjectType,
    )
    const SubjectDetailsComponent = subjectTypeInstance.detailComponent

    return (
        <>
            <Container style={{ display: 'block' }}>
                <div style={{ display: 'flex' }}>
                    <Box className={classes.profileContainer}>
                        <SubjectDetailsComponent
                            data={data}
                            accessRecipient={accessRecipient}
                            refetch={refetch}
                        />
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box className={classes.detailsContainer}>
                        {data?.currentApprovalStepName && (
                            <AttributeContainer>
                                <AttributeLabelLarge>
                                    {t('Step')}
                                </AttributeLabelLarge>
                                <AttributeValue
                                    style={{
                                        fontWeight: 700,
                                    }}
                                    value={data?.currentApprovalStepName}
                                />
                            </AttributeContainer>
                        )}
                        <VerticalAlignedLabel
                            style={{
                                color: '#5d6870',
                                fontWeight: '600',
                                marginBottom: '-10px',
                                maxWidth: '50%',
                            }}
                        >
                            {t('PercentageCompleted', {
                                value: `${roundOffNumber(
                                    data?.percentageCompleted * 100,
                                )} %`,
                            })}
                        </VerticalAlignedLabel>
                        <AttributeContainer
                            className={classes.progressAttribute}
                        >
                            <Box className={classes.progressIndicatorContainer}>
                                <Box>
                                    <ProgressIndicator
                                        percentageCompleted={
                                            data?.percentageCompleted * 100
                                        }
                                        width={'275'}
                                    />
                                </Box>
                                <Box display="flex">
                                    <StatusIndicator
                                        size="large"
                                        variant={itemStatus}
                                        label={t(`${itemStatus}`)}
                                        style={{
                                            minWidth: 'fit-content',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </AttributeContainer>
                        <AttributeContainer>
                            <VerticalAlignedLabel>
                                {t('Requestor')}
                            </VerticalAlignedLabel>
                            <Box className={classes.person}>
                                <Person.Details
                                    imgSrc={getFullImageUrl(
                                        initiatorPerson?.imageThumbUrl,
                                    )}
                                    size="large"
                                    name={
                                        initiatorPerson
                                            ? initiatorPerson.friendlyName
                                            : data?.initiatorPersonFriendlyName
                                    }
                                    status={initiatorPerson?.login}
                                    statusStyles={statusStyles}
                                    showTooltip={true}
                                />
                            </Box>
                        </AttributeContainer>
                        <AttributeContainer>
                            <AttributeLabelLarge>
                                {t('RequestID')}
                            </AttributeLabelLarge>
                            <AttributeValue
                                value={data?.businessRequestNumber}
                            />
                        </AttributeContainer>
                        <AttributeContainer>
                            <AttributeLabelLarge>
                                {t('BusinessRequestName')}
                            </AttributeLabelLarge>

                            <Link
                                to={`${appConfig.APP_SUBPATH}/${areaName}/businessRequests?id=${data.businessRequestId}`}
                                style={styles.linkStyles}
                            >
                                <TextFormatter
                                    value={data?.businessRequestName}
                                    style={styles.linkStyles}
                                    maxCharacters={100}
                                    showTooltip="always"
                                />
                            </Link>
                        </AttributeContainer>
                        {data.businessRequestStatus && (
                            <AttributeContainer>
                                <AttributeLabelLarge>
                                    {t('RequestStatus')}
                                </AttributeLabelLarge>
                                <StatusIndicator
                                    size="small"
                                    variant={data.businessRequestStatus}
                                    label={t(`${data.businessRequestStatus}`)}
                                    style={{
                                        minWidth: 'fit-content',
                                    }}
                                />
                            </AttributeContainer>
                        )}

                        {data?.fulfillmentStatusId > 0 &&
                            data?.fulfillmentStatusFriendlyName && (
                                <AttributeContainer>
                                    <AttributeLabelLarge>
                                        {t('FulfillmentStatus')}
                                    </AttributeLabelLarge>
                                    <StatusIndicator
                                        label={`${t('Fulfillment')} ${
                                            data?.fulfillmentStatusFriendlyName
                                        }`}
                                        size="small"
                                        style={{
                                            minWidth: 'fit-content',
                                        }}
                                    />
                                </AttributeContainer>
                            )}

                        <AttributeContainer>
                            <AttributeLabelLarge>
                                {t('TargetResource')}
                            </AttributeLabelLarge>

                            <TextFormatter
                                value={data?.resourceRequested}
                                style={styles.attributeValue}
                                maxCharacters={100}
                                showTooltip="always"
                            />
                        </AttributeContainer>

                        {hasAccessToFulfillmentScheduleDateControl &&
                            data?.fulFillmentScheduleDateUtc && (
                                <AttributeContainer
                                    data-protectedsubcomponent={
                                        hasAccessToFulfillmentScheduleDateControl
                                    }
                                >
                                    <AttributeLabelLarge>
                                        {t('FulfillmentScheduleDate')}
                                    </AttributeLabelLarge>
                                    <AttributeValue
                                        value={
                                            !data ||
                                            !data.fulFillmentScheduleDateUtc
                                                ? ''
                                                : moment
                                                      .utc(
                                                          data.fulFillmentScheduleDateUtc,
                                                      )
                                                      .local()
                                                      .format('LLL')
                                        }
                                    ></AttributeValue>
                                </AttributeContainer>
                            )}

                        {(hasAccessToFulfillmentScheduleDateControl ||
                            hasAccessToEditFulfillmentScheduleDateControl) &&
                            data?.fulFillmentScheduleDateUtc && (
                                <AttributeContainer
                                    data-protectedsubcomponent={`${hasAccessToFulfillmentScheduleDateControl} OR ${hasAccessToEditFulfillmentScheduleDateControl}`}
                                >
                                    <AttributeLabelLarge>
                                        {t('FulfillmentScheduleDate')}
                                    </AttributeLabelLarge>

                                    <FulfillmentScheduleDateControl
                                        item={data}
                                        onEdit={editFulfillmentDate}
                                        withOutLabel={true}
                                    />
                                </AttributeContainer>
                            )}

                        <AttributeContainer>
                            <AttributeLabelLarge>
                                {t('CreatedOn')}
                            </AttributeLabelLarge>
                            <AttributeValue
                                value={
                                    !data
                                        ? ''
                                        : moment
                                              .utc(data.createdDateUtc)
                                              .local()
                                              .format('LLL')
                                }
                            />
                        </AttributeContainer>

                        {data?.dueDateUtc && (
                            <AttributeContainer>
                                <AttributeLabelLarge>
                                    {t('DueDate')}
                                </AttributeLabelLarge>
                                <AttributeValue
                                    value={
                                        !data || !data.dueDateUtc
                                            ? ''
                                            : moment
                                                  .utc(data.dueDateUtc)
                                                  .local()
                                                  .format('LLL')
                                    }
                                />
                            </AttributeContainer>
                        )}

                        <AttributeContainer>
                            <AttributeLabelLarge>
                                {t('LastModifiedOn')}
                            </AttributeLabelLarge>
                            <AttributeValue
                                value={
                                    !data
                                        ? ''
                                        : moment
                                              .utc(data.modifiedDateUtc)
                                              .local()
                                              .format('LLL')
                                }
                            />
                        </AttributeContainer>

                        <AttributeContainer>
                            <AttributeLabelLarge>
                                {t('ExpirationDate')}
                            </AttributeLabelLarge>

                            <AttributeValue
                                value={
                                    !data
                                        ? ''
                                        : moment
                                              .utc(data.expirationDateUtc)
                                              .local()
                                              .format('LLL')
                                }
                            />
                        </AttributeContainer>

                        {data.additionalProperties &&
                            Object.keys(data.additionalProperties).map((k) => (
                                <AttributeContainer key={k}>
                                    <AttributeLabelLarge>
                                        {t(`${k}`)}
                                    </AttributeLabelLarge>

                                    <AttributeValue
                                        value={data.additionalProperties[
                                            k
                                        ]?.toString()}
                                    />
                                </AttributeContainer>
                            ))}
                        {/* Time contrainst start */}
                        <Divider />
                        <Box style={timeSectionStyles}>
                            <Box>
                                {data && data.requestDataAssignmentStartDate && (
                                    <AttributeContainer alignItems={'center'}>
                                        <AttributeLabelLarge
                                            className={classes.accessDatesLabel}
                                        >
                                            {t('AccessStartDate')}
                                        </AttributeLabelLarge>
                                        <Icon
                                            name={'StartTime'}
                                            style={{
                                                marginRight: '10px',
                                                color:
                                                    theme?.palette?.primary
                                                        ?.main,
                                            }}
                                        />
                                        <AttributeValue
                                            className={classNames(
                                                classes.accessDatesValues,
                                                {
                                                    [classes.dateWarning]: isDateInPast(
                                                        data.requestDataAssignmentEndDate,
                                                    ),
                                                },
                                            )}
                                            style={{ width: 'max-content' }}
                                            value={moment
                                                .utc(
                                                    data.requestDataAssignmentStartDate,
                                                )
                                                .local()
                                                .format('LL')}
                                        />
                                    </AttributeContainer>
                                )}

                                {data && data.requestDataAssignmentEndDate && (
                                    <AttributeContainer alignItems={'center'}>
                                        <AttributeLabelLarge
                                            className={classes.accessDatesLabel}
                                        >
                                            {t('AccessEndDate')}
                                        </AttributeLabelLarge>
                                        <Icon
                                            name={'EndTime'}
                                            style={{
                                                marginRight: '10px',
                                                color:
                                                    theme?.palette?.primary
                                                        ?.main,
                                            }}
                                        />
                                        <AttributeValue
                                            className={classNames(
                                                classes.accessDatesValues,
                                                {
                                                    [classes.dateWarning]: isDateInPast(
                                                        data.requestDataAssignmentEndDate,
                                                    ),
                                                },
                                            )}
                                            style={{ width: 'max-content' }}
                                            value={moment
                                                .utc(
                                                    data.requestDataAssignmentEndDate,
                                                )
                                                .local()
                                                .format('LL')}
                                        />
                                    </AttributeContainer>
                                )}
                            </Box>
                        </Box>
                        {isDateInPast(data.requestDataAssignmentEndDate) && (
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                marginLeft={'1.3rem'}
                            >
                                <Icon
                                    name="Warning"
                                    color={theme?.palette?.warning?.main}
                                    height="1rem"
                                    width="1rem"
                                />
                                <Typography className={classes.warningLabel}>
                                    {`${t('Warning')}:`}
                                </Typography>
                                <Typography className={classes.warningMessage}>
                                    {t('TimeConstraintExceededMessage')}
                                </Typography>
                            </Box>
                        )}
                        {/* Time contrainst end */}
                    </Box>
                </div>

                {data && data.businessRequestItem_AdditionalFields && (
                    <Box style={{ position: 'relative' }}>
                        <Divider />
                        <Button
                            onClick={() => setShowMore(!showMore)}
                            style={showMoreButtonStyles}
                            endIcon={
                                <Icon
                                    name="Arrow"
                                    direction={showMore ? 'up' : 'down'}
                                    style={{ width: '16px', height: '16px' }}
                                    color="#2471ab"
                                />
                            }
                        >
                            {showMore ? t('ShowLess') : t('ShowMore')}
                        </Button>
                        <CustomAttribute
                            padding={'23px 0px 8px 0px'}
                            className={classes.horizontalOrVertical}
                            label={t('DynamicRequestAttributes')}
                        />

                        {showMore && (
                            <Box display="flex">
                                <Box>
                                    <Icon
                                        name="dynamicForm"
                                        height="45px"
                                        width="45px"
                                    />
                                </Box>
                                <Box style={{ marginLeft: '34px' }}>
                                    <Grid container spacing={1}>
                                        {data &&
                                            data
                                                ?.businessRequestItem_AdditionalFields
                                                ?.length &&
                                            data.businessRequestItem_AdditionalFields.map(
                                                (item, index) => (
                                                    <Grid item xs={6}>
                                                        <AdditionalField
                                                            key={index}
                                                            className={
                                                                classes.additionalField
                                                            }
                                                            value={
                                                                item.AdditionalField_Value
                                                            }
                                                            label={
                                                                item.AdditionalField_LocaleKey
                                                                    ? t(
                                                                          `${NS}:MsCommonAnonymous_${item.AdditionalField_LocaleKey}`,
                                                                      )
                                                                    : item.AdditionalField_Name
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )}
                                    </Grid>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Container>
        </>
    )
}

export default Overview
